
import { Component, Vue } from 'vue-property-decorator';
import WorkflowContainer from '@/components/views/Workflow/WorkflowContainer.vue';
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import LocaleRepository from '@/repositories/LocaleRepository';
import Locale from '@/models/Locale';
import ProjectLabelTemplate from '@/models/ProjectLabelTemplate';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import SettingsTopBar from '../components/views/settings/SettingsTopBar.vue';

@Component({
  name: 'WorkflowTemplateView',
  components: { WorkflowContainer, SettingsTopBar },
  mixins: [CheckUnsavedDataMixin],
})
export default class WorkflowTemplateView extends Vue {
  private hasUnsavedData = false;
  private locale = 'sl';
  private isLoaded = false;

  private get locales() {
    return LocaleRepository.getAll();
  }

  private onChangesInDataMade({ state }: { state: boolean }) {
    this.hasUnsavedData = state;
  }

  private async onLocaleChange(locale: string) {
    // @ts-ignore
    if (this.hasUnsavedData && !this.confirmLeave()) {
      return;
    }

    this.locale = locale;
    this.hasUnsavedData = false;
  }

  private async created() {
    EventBus.$on(EventBusEvents.changesInDataMade, this.onChangesInDataMade);
    await ProjectLabelTemplate.getAll();
    await Locale.getAll();

    ProjectLabelTemplate.insertOrUpdate({
      data: {
        id: GlobalOptions.ALL,
        name: this.$t('Svi') as string,
      },
    });

    this.isLoaded = true;
  }

  private beforeDestroy() {
    EventBus.$off(EventBusEvents.changesInDataMade, this.onChangesInDataMade);
  }
}
